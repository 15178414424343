export const FACTORY_ADDRESS = '0x347D14b13a68457186b2450bb2a6c2Fd7B38352f'
export const WXDC = 'xdc951857744785e80e2de051c32ee7b25f9c458c42'
export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

export const TOKEN_LIST_REPO = 'https://raw.githubusercontent.com/pro100skm/xdc-token-list/master'
// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [`${TOKEN_LIST_REPO}/mainnet.tokenlist.json`]
export const BLOCKED_LIST_URL = `${TOKEN_LIST_REPO}/blocked.list.json`

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0x495c7f3a713870f68f8b418b355c085dfdc412c3',
  '0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
  '0xe31debd7abff90b06bca21010dd860d8701fd901',
  '0xfc989fbb6b3024de5ca0144dc23c18a063942ac1',
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76',
  '0x93b2fff814fcaeffb01406e80b4ecd89ca6a021b',

  // rebass tokens
  '0x9ea3b5b4ec044b70375236a281986106457b20ef',
  '0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
  '0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
  '0xfae9c647ad7d89e738aba720acf09af93dc535f7',
  '0x7296368fe9bcb25d3ecc19af13655b907818cc09',
  '0xd3de36d7ce3e6a5f072363fe0f9541e59f86aa3e', // GRAMZ
  '0x24aedad94a0046d984a3474e00ed45fd3a24c8f9', // BUDZ
  '0x37f62895c69e36cff78994747b9257f8818411d5',
  '0x0798f61f2842af31795AdEd8EEE811f8d0eD302B', // BBDAO
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0xb6a741f37d6e455ebcc9f17e2c16d0586c3f57a5',
  '0x97cb8cbe91227ba87fc21aaf52c4212d245da3f8',
  '0x1acba73121d5f63d8ea40bdc64edb594bd88ed09',
  '0x7d7e813082ef6c143277c71786e5be626ec77b20',
  '0x5a25fefc8e8c1e0d533dd684677e75e456ef6259', // GRAMZ-WXDC
  '0xa6dcc24b56e5304651e0d986a24d21cf3daf7240', // GRAMZ-XSP
  '0xed308f3f6fe30377e1cefbc0b9d292e490b70f3d', // BUDZ-GRAMZ
  '0x1d811e6664034a42b1f2a1a06b1f8b3d0f5789c2', // BBDAO-xUSDT
  '0xa22c7135bdb263d5ddb6e57eeb35e1607385e732', // BBDAO-WXDC
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76':
    'TikTok Inc. has asserted this token is violating its trademarks and therefore is not available.',
  '0x37f62895c69e36cff78994747b9257f8818411d5': 'Scam Project',
}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = ['0xd46ba6d942050d489dbd938a2c909a5d5039a161']

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or XDC pairings.'

// pairs that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES_PAIRS = [
  '0x9928e4046d7c6513326ccea028cd3e7a91c7590a',
  '0x87da823b6fc8eb8575a235a824690fda94674c88',
  '0xcd7989894bc033581532d2cd88da5db0a4b12859',
  '0xe1573b9d29e2183b1af0e743dc2754979a40d237',
  '0x45804880de22913dafe09f4980848ece6ecbaf78',
  '0x709f7b10f22eb62b05913b59b92ddd372d4e2152',
]

// tokens that should be tracked but arent due to lag in subgraph
// all pairs that include token will be tracked
export const TRACKED_OVERRIDES_TOKENS = ['0xd4b5f10d61916bd6e0860144a91ac658de8a1437']

export const TOKEN_WHITELIST = [
  '0x17476dc3eda45ad916ceaddea325b240a7fb259d', // XTT
  '0x36726235dAdbdb4658D33E62a249dCA7c4B2bC68', // XSP
]
